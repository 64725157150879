<template>
  <div class="star" :style="styleObject">
    <img :src="starSVG" alt="Star" />
  </div>
</template>

<script>
import starSVG from "../assets/blume.svg";

export default {
  props: ["x", "y", "size", "rotationSpeed"],
  data() {
    return {
      starSVG: starSVG, // Assuming your SVG is named star.svg and is directly inside the assets folder
    };
  },
  computed: {
    styleObject() {
      return {
        left: this.x + "px",
        top: this.y + "px",
        width: this.size + "px",
        height: this.size + "px",
        animationDuration: this.rotationSpeed + "s",
        "--moveX": Math.random() * 200 - 100 + "px", // Random movement between -100px and 100px on X axis
        "--moveY": Math.random() * 200 - 100 + "px", // Random movement between -100px and 100px on Y axis
        transform: "rotate(" + Math.random() * 360 + "deg)", // Random initial rotation
      };
    },
  },
};
</script>

<style scoped>
.star {
  position: absolute;
  animation: 
    rotate infinite linear,
    move 30s infinite alternate; /* 30s is just an example duration */
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes move {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translateX(var(--moveX)) translateY(var(--moveY));
  }
}

</style>
