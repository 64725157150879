<template>
  <v-container fluid style="background-color: #477db3; height: 100vh">
    <Star
      v-for="(star, index) in stars"
      :key="index"
      :x="star.x"
      :y="star.y"
      :size="star.size"
      :rotationSpeed="star.rotationSpeed"
    />
    <v-card elevation="0" color="transparent"
      ><v-card-title
        style="text-align: center; padding: 20px; margin-top: 150px"
        class="newl"
        >zeck</v-card-title
      ><v-card-title style="text-align: center; padding: 0px; margin-top: 50px"
        ><v-btn
          elevation="0"
          to="/play"
          color="transparent"
          width="350"
          height="50"
          :ripple="false"
          class="text-none newlitem"
          >sampler starten</v-btn
        ></v-card-title
      >

      <v-card-title style="text-align: center; padding: 0px; margin-top: 20px"
        ><v-btn
          elevation="0"
          color="transparent"
          width="300"
          to="/leaderboard"
          height="50"
          :ripple="false"
          class="text-none newlitem"
          >your versions &lt;3</v-btn
        ></v-card-title
      >
      <v-card-title style="text-align: center; padding: 0px; margin-top: 20px"
        ><v-btn
          elevation="0"
          color="transparent"
          width="250"
          height="50"
          @click.prevent="openSpotify"
          :ripple="false"
          class="text-none newlitem"
          >spotify</v-btn
        ></v-card-title
      >
      <!-- <v-card-title
        style="text-align: center; padding: 0px;margin-top:20px"
        class="newlitem"
        >liederboard</v-card-title
      > -->
    </v-card>
  </v-container>
  <!-- <div id="container" :class="{ mobile: mobile }">
   
    <div id="buttons-container">
          <v-card-title class="headline">zeck</v-card-title>
          <v-card-title class="headline">asf</v-card-title>

    
    </div>
  </div> -->
</template>

<script>
// import $ from "jquery";
import { useDisplay } from "vuetify";
import Star from "./Star.vue";

export default {
  name: "StartPage",
  components: { Star },
  data() {
    return {
      stars: [],
    };
  },

  computed: {
    mobile() {
      const { mobile } = useDisplay();

      return mobile.value;
    },
  },
  mounted() {
    this.createStars();

    // $(document).ready(function () {
    //   $("#container").fadeIn(300);
    //   $("#hubi").delay(300).fadeIn(300).animate(
    //     {
    //       bottom: "-200",
    //       left: "20%",
    //     },
    //     90000,
    //     "linear"
    //   );
    // });
  },
  methods: {
    openSpotify() {
      window.open(
        "https://open.spotify.com/intl-de/track/5jJVJ9eugaHMJzHXxijk2U?si=3bdf346b86b343c7",
        "_blank"
      );
    },
    createStars() {
      for (let i = 0; i < 50; i++) {
        // Create 50 stars as an example
        const x = Math.random() * window.innerWidth;
        const y = Math.random() * window.innerHeight;
        const size = Math.random() * 100 + 10; // Random size between 10 and 110
        const rotationSpeed = Math.random() * 5 + 1; // Random rotation speed between 1 and 6 seconds
        this.stars.push({ x, y, size, rotationSpeed });
      }
    },
  },
};
</script>

<style scoped>
.newl {
  font-size: 80px !important;
  color: white;
  font-family: "Praline-Regular", sans-serif;
}

.newlitem {
  font-size: 30px !important;
  color: white !important;
  font-family: "Praline-Regular", sans-serif;
}

#no-background-hover::before {
  background-color: transparent !important;
}

.no-background-hover::before {
  background-color: transparent !important;
}
</style>
