import "./init"
import App from "./App.vue";
import { createApp } from "vue";
import GoogleAnalytics from './google-analytics.js';  // Import the plugin

// Import the necessary libraries for Font Awesome
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faPlay,
  faPause,
  faUserSecret,
  faMicrophoneLines,
  faGuitar,
  faDrum,
  faWaveSquare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

// Add the icons to the library so you can use them in your templates
library.add(faUserSecret, faMicrophoneLines, faGuitar, faDrum, faPlay, faPause, faWaveSquare);

import router from "./router"; // Import the router configuration
import "./styles/global.css"; // Adjust the path based on where you placed the CSS file

// Vuetify
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import axios from "./axios";

const vuetify = createVuetify({
  components,
  directives,
});

const app = createApp(App);
app.config.globalProperties.$axios = axios;

// Register the FontAwesomeIcon component globally
app.component("font-awesome-icon", FontAwesomeIcon);

app.use(vuetify).use(router).use(GoogleAnalytics, { gaId: 'G-7ZG7K1EBMF' }).mount("#app");  // Use the plugin with your Google Analytics ID
