<template>
  <span
    density="comfortable"
    class="stepbutton"
    :class="{ active: pressed || tempPressed }"
    @click="clickAction"
    ><slot></slot
  ></span>
</template>

<script>
import Color from "color";

export default {
  name: "Machinebutton",
  props: ["pressed", "color"],
  data() {
    return {
      tempPressed: false,
    };
  },
  computed: {
    backgroundColor() {
      let bgColor = this.color || "#CDD8B7";
      bgColor = Color(bgColor);
      if (this.pressed || this.tempPressed)
        bgColor = bgColor.darken(0.1).desaturate(0.2);

      return bgColor.string();
    },
  },
  methods: {
    clickAction() {
      if (!this.pressed) {
        this.tempPressed = true;
        setTimeout(() => {
          this.tempPressed = false;
        }, 200);
      }
      // this.$emit("click");
    },
  },
};
</script>

<style scoped>
.stepbutton {
  text-align: center; /* Aligns the text to the right */
  vertical-align: center; /* Aligns the text to the top */
  line-height: normal; /* Resets the line height */
  padding: 5px 6px 6px 6px; /* Adjusts the position of the text within the button */
  border-width: 0px 1px 4px 1px;
  box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 1),
    inset 0px 0px 0px 3px rgba(0, 0, 0, 0.1);
  background-color: #77A0C8;
  color: black;
  text-transform: uppercase;
  display: inline-block;
  width: 57px;
  height: 48px;
  border-radius: 5px;
  cursor: pointer;
  margin: 8px 0px;
  font-size: 13px;
  font-family: "URW-Bold", sans-serif;
  outline: 3px solid #3D3D3D;

  position: relative;
}
.stepbutton.active {
  border-bottom-width: 1px;
  box-shadow: 0px 1px 0px 1px rgba(0, 0, 0, 0.3),
    inset 0px 0px 0px 3px rgba(0, 0, 0, 0.1);
  color: black;

  display: inline-block;

  cursor: pointer;
  margin: 8px 0px;
}
</style>
