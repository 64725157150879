export default [
  {
    name: "Intro",
    short: "IN",
    pattern: [
      [
        { active: true },
        { active: false },
        { active: false },
        { active: false },
        { active: true },
        { active: false },
        { active: false },
        { active: false },
        { active: true },
        { active: false },
        { active: false },
        { active: false },
        { active: true },
        { active: false },
        { active: false },
        { active: false },
      ],
      [
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
      ],
      [
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
      ],
      [
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
      ],
      [
        { active: true },
        { active: false },
        { active: false },
        { active: false },
        { active: true },
        { active: false },
        { active: false },
        { active: false },
        { active: true },
        { active: false },
        { active: false },
        { active: false },
        { active: true },
        { active: false },
        { active: false },
        { active: false },
      ],
    ],
    instruments: [
      {
        freq: 759.2,
        gain: 1,
        decay: 0.25928883937922625,
        endPitch: 0.1981,
        sineNoiseMix: 0.0793,
      },
      {
        freq: 759.2,
        gain: 1,
        decay: 0.25928883937922625,
        endPitch: 0.1981,
        sineNoiseMix: 0.0793,
      },
      {
        freq: 759.2,
        gain: 1,
        decay: 0.25928883937922625,
        endPitch: 0.1981,
        sineNoiseMix: 0.0793,
      },
      {
        freq: 759.2,
        gain: 1,
        decay: 0.25928883937922625,
        endPitch: 0.1981,
        sineNoiseMix: 0.0793,
      },
      {
        freq: 759.2,
        gain: 1,
        decay: 0.25928883937922625,
        endPitch: 0.1981,
        sineNoiseMix: 0.0793,
      },
    ],
    tempo: 120,
  },
  {
    name: "Verse 1",
    short: "V1",
    pattern: [
      [
        { active: true },
        { active: false },
        { active: false },
        { active: false },
        { active: true },
        { active: false },
        { active: false },
        { active: false },
        { active: true },
        { active: false },
        { active: false },
        { active: false },
        { active: true },
        { active: false },
        { active: false },
        { active: false },
      ],
      [
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
      ],
      [
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
      ],
      [
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
      ],
      [
        { active: true },
        { active: false },
        { active: false },
        { active: false },
        { active: true },
        { active: false },
        { active: false },
        { active: false },
        { active: true },
        { active: false },
        { active: false },
        { active: false },
        { active: true },
        { active: false },
        { active: false },
        { active: false },
      ],
    ],
    instruments: [
      {
        freq: 759.2,
        gain: 1,
        decay: 0.25928883937922625,
        endPitch: 0.1981,
        sineNoiseMix: 0.0793,
      },
      {
        freq: 759.2,
        gain: 1,
        decay: 0.25928883937922625,
        endPitch: 0.1981,
        sineNoiseMix: 0.0793,
      },
      {
        freq: 759.2,
        gain: 1,
        decay: 0.25928883937922625,
        endPitch: 0.1981,
        sineNoiseMix: 0.0793,
      },
      {
        freq: 759.2,
        gain: 1,
        decay: 0.25928883937922625,
        endPitch: 0.1981,
        sineNoiseMix: 0.0793,
      },
      {
        freq: 759.2,
        gain: 1,
        decay: 0.25928883937922625,
        endPitch: 0.1981,
        sineNoiseMix: 0.0793,
      },
    ],
    tempo: 120,
  },
  {
    name: "Chorus",
    short: "CH",
    pattern: [
      [
        { active: true },
        { active: false },
        { active: false },
        { active: false },
        { active: true },
        { active: false },
        { active: false },
        { active: false },
        { active: true },
        { active: false },
        { active: false },
        { active: false },
        { active: true },
        { active: false },
        { active: false },
        { active: false },
      ],
      [
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: true },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: true },
        { active: false },
        { active: false },
        { active: false },
      ],
      [
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
      ],
      [
        { active: false },
        { active: false },
        { active: true },
        { active: false },
        { active: false },
        { active: false },
        { active: true },
        { active: false },
        { active: false },
        { active: false },
        { active: true },
        { active: false },
        { active: false },
        { active: false },
        { active: true },
        { active: false },
      ],
      [
        { active: true },
        { active: false },
        { active: false },
        { active: false },
        { active: true },
        { active: false },
        { active: false },
        { active: false },
        { active: true },
        { active: false },
        { active: false },
        { active: false },
        { active: true },
        { active: false },
        { active: false },
        { active: false },
      ],
    ],
    instruments: [
      {
        freq: 759.2,
        gain: 1,
        decay: 0.25928883937922625,
        endPitch: 0.1981,
        sineNoiseMix: 0.0793,
      },
      {
        freq: 759.2,
        gain: 1,
        decay: 0.25928883937922625,
        endPitch: 0.1981,
        sineNoiseMix: 0.0793,
      },
      {
        freq: 759.2,
        gain: 1,
        decay: 0.25928883937922625,
        endPitch: 0.1981,
        sineNoiseMix: 0.0793,
      },
      {
        freq: 759.2,
        gain: 1,
        decay: 0.25928883937922625,
        endPitch: 0.1981,
        sineNoiseMix: 0.0793,
      },
      {
        freq: 759.2,
        gain: 1,
        decay: 0.25928883937922625,
        endPitch: 0.1981,
        sineNoiseMix: 0.0793,
      },
    ],
    tempo: 120,
  },
  {
    name: "Verse 2",
    short: "V2",
    pattern: [
      [
        { active: true },
        { active: false },
        { active: false },
        { active: false },
        { active: true },
        { active: false },
        { active: false },
        { active: false },
        { active: true },
        { active: false },
        { active: false },
        { active: false },
        { active: true },
        { active: false },
        { active: false },
        { active: false },
      ],
      [
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: true },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: true },
        { active: false },
        { active: false },
        { active: false },
      ],
      [
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
      ],
      [
        { active: false },
        { active: false },
        { active: true },
        { active: false },
        { active: false },
        { active: false },
        { active: true },
        { active: false },
        { active: false },
        { active: false },
        { active: true },
        { active: false },
        { active: false },
        { active: false },
        { active: true },
        { active: false },
      ],
      [
        { active: true },
        { active: false },
        { active: false },
        { active: false },
        { active: true },
        { active: false },
        { active: false },
        { active: false },
        { active: true },
        { active: false },
        { active: false },
        { active: false },
        { active: true },
        { active: false },
        { active: false },
        { active: false },
      ],
    ],
    instruments: [
      {
        freq: 759.2,
        gain: 1,
        decay: 0.25928883937922625,
        endPitch: 0.1981,
        sineNoiseMix: 0.0793,
      },
      {
        freq: 759.2,
        gain: 1,
        decay: 0.25928883937922625,
        endPitch: 0.1981,
        sineNoiseMix: 0.0793,
      },
      {
        freq: 759.2,
        gain: 1,
        decay: 0.25928883937922625,
        endPitch: 0.1981,
        sineNoiseMix: 0.0793,
      },
      {
        freq: 759.2,
        gain: 1,
        decay: 0.25928883937922625,
        endPitch: 0.1981,
        sineNoiseMix: 0.0793,
      },
      {
        freq: 759.2,
        gain: 1,
        decay: 0.25928883937922625,
        endPitch: 0.1981,
        sineNoiseMix: 0.0793,
      },
    ],
    tempo: 120,
  },
  {
    name: "Bridge 1",
    short: "B1",
    pattern: [
      [
        { active: true },
        { active: false },
        { active: false },
        { active: false },
        { active: true },
        { active: false },
        { active: false },
        { active: false },
        { active: true },
        { active: false },
        { active: false },
        { active: false },
        { active: true },
        { active: false },
        { active: false },
        { active: false },
      ],
      [
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: true },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: true },
        { active: false },
        { active: false },
        { active: false },
      ],
      [
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
      ],
      [
        { active: false },
        { active: false },
        { active: true },
        { active: false },
        { active: false },
        { active: false },
        { active: true },
        { active: false },
        { active: false },
        { active: false },
        { active: true },
        { active: false },
        { active: false },
        { active: false },
        { active: true },
        { active: false },
      ],
      [
        { active: true },
        { active: false },
        { active: false },
        { active: false },
        { active: true },
        { active: false },
        { active: false },
        { active: false },
        { active: true },
        { active: false },
        { active: false },
        { active: false },
        { active: true },
        { active: false },
        { active: false },
        { active: false },
      ],
    ],
    instruments: [
      {
        freq: 759.2,
        gain: 1,
        decay: 0.25928883937922625,
        endPitch: 0.1981,
        sineNoiseMix: 0.0793,
      },
      {
        freq: 759.2,
        gain: 1,
        decay: 0.25928883937922625,
        endPitch: 0.1981,
        sineNoiseMix: 0.0793,
      },
      {
        freq: 759.2,
        gain: 1,
        decay: 0.25928883937922625,
        endPitch: 0.1981,
        sineNoiseMix: 0.0793,
      },
      {
        freq: 759.2,
        gain: 1,
        decay: 0.25928883937922625,
        endPitch: 0.1981,
        sineNoiseMix: 0.0793,
      },
      {
        freq: 759.2,
        gain: 1,
        decay: 0.25928883937922625,
        endPitch: 0.1981,
        sineNoiseMix: 0.0793,
      },
    ],
    tempo: 120,
  },
  {
    name: "Bridge 2",
    short: "B2",
    pattern: [
      [
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
      ],
      [
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
      ],
      [
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
      ],
      [
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
      ],
      [
        { active: true },
        { active: false },
        { active: false },
        { active: false },
        { active: true },
        { active: false },
        { active: false },
        { active: false },
        { active: true },
        { active: false },
        { active: false },
        { active: false },
        { active: true },
        { active: false },
        { active: false },
        { active: false },
      ],
    ],
    instruments: [
      {
        freq: 759.2,
        gain: 1,
        decay: 0.25928883937922625,
        endPitch: 0.1981,
        sineNoiseMix: 0.0793,
      },
      {
        freq: 759.2,
        gain: 1,
        decay: 0.25928883937922625,
        endPitch: 0.1981,
        sineNoiseMix: 0.0793,
      },
      {
        freq: 759.2,
        gain: 1,
        decay: 0.25928883937922625,
        endPitch: 0.1981,
        sineNoiseMix: 0.0793,
      },
      {
        freq: 759.2,
        gain: 1,
        decay: 0.25928883937922625,
        endPitch: 0.1981,
        sineNoiseMix: 0.0793,
      },
      {
        freq: 759.2,
        gain: 1,
        decay: 0.25928883937922625,
        endPitch: 0.1981,
        sineNoiseMix: 0.0793,
      },
    ],
    tempo: 120,
  },
];
