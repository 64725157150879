<template>
  <span
    class="stepbutton"
    :class="{ active: value, highlight: highlight }"
    @click="changeValue"
  >
    <span class="number"><img :src="opener" alt="SVG Image" /></span>

    <!-- <span class="number">{{ number }}</span> -->
  </span>
</template>

<script>
import VX from "../assets/VX.svg";
import BA from "../assets/BA.svg";
import DR from "../assets/DR.svg";
import GT from "../assets/GT.svg";

export default {
  name: "stepbutton",
  props: ["value", "highlight", "number", "channel"],
  methods: {
    changeValue() {
      this.$emit("input", !this.value);
      this.$emit("setAudioVolume", {
        active: !this.value,
        track: this.channel,
      });
    },
  },
  mounted() {
    if (this.number == "VX") {
      this.opener = this.VX;
    } else if (this.number == "BA") {
      this.opener = this.BA;
    } else if (this.number == "DR") {
      this.opener = this.DR;
    } else if (this.number == "GT") {
      this.opener = this.GT;
    }
  },

  data() {
    return {
      GT: GT, // Assuming your SVG is named star.svg and is directly inside the assets folder
      DR: DR,
      BA: BA,
      VX: VX,
      opener: null,
    };
  },
  computed: {
    bar() {
      return Math.floor((this.number - 1) / 4) + 1;
    },
    subNumber() {
      return ((this.number - 1) % 4) + 1;
    },
  },
};
</script>

<style scoped>
.stepbutton {
  display: inline-block;
  align-items: center;
  justify-content: center;
  text-align: center; /* Aligns the text to the right */
  line-height: 30px; /* Resets the line height */
  padding: 6px 6px 6px 6px; /* Adjusts the position of the text within the button */
  border-width: 0px 1px 4px 1px;
  box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.2),
    inset 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
  background-color: #adbdd3;
  color: black;
  min-width: 35px;
  height: 30px;
  border-radius: 5px;
  cursor: pointer;
  margin: 2px 0px;
  font-size: 12px;
  text-transform: uppercase;
  font-family: "URW-Bold", sans-serif;
  position: relative;
}

.stepbutton.active {
  border-bottom-width: 1px;
  box-shadow: 0px 1px 0px 0px rgba(250, 85, 85, 0),
    inset 0px 0px 0px 2px #3d3d3d;
  background-color: #36b269;

  cursor: pointer;
  margin: 2px 0px;
}

.bottom-text {
  text-transform: uppercase;
  position: absolute;
  bottom: 6px;
  left: 6px;
  border: 1px solid white;
  border-radius: 4px;
  font-size: 7px;

  color: white;
  padding: 1px 2px 1px 2px;
}
.stepbutton.active .bottom-text {
  border: 1px solid #c05a56;

  color: #c05a56;
}

.number img {
  width: 18px;
  height: 18px;
  margin: auto;
filter: saturate(0) brightness(0);
}
</style>

