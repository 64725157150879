<template>
  <v-container class="d-flex justify-center align-center fill-height" fluid>
    <v-card style="max-width: 600px; width: 100%">
      <v-card-title
        style="font-size: 30px; padding-top: 40px; padding-bottom: 10px"
        >your versions &lt;3</v-card-title
      >
      <v-list>
        <v-list-item-group v-for="(song, index) in songs" :key="song.id">
          <v-list-item>
            <!-- Content Platzierung -->
            <v-list-item-content
              class="d-flex justify-space-between align-center"
              style="margin-bottom: 10px; padding-left: 15px"
            >
              <!-- Counter with hover effect -->
              <div style="margin-right: 20px">
                <button @click="togglePlayPause(song.id)" style="padding: 10px">
                  <font-awesome-icon
                    v-if="currentPlayingSong !== song.id"
                    icon="play"
                  />
                  <font-awesome-icon v-else icon="pause" />
                </button>
              </div>

              <!-- Songname and Username -->
              <div class="flex-grow-1 ml-2">
                <v-list-item-title>
                  {{ song.artist_name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  zeck x {{ song.artist_name }}
                </v-list-item-subtitle>
              </div>

              <!-- Datum und Länge rechtsbündig platzieren -->
              <div class="text-right">
                <v-list-item-subtitle style="padding-right: 15px">
                  {{ song.length }}
                </v-list-item-subtitle>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
  </v-container>
</template>



<style scoped>
.v-card {
  background-color: #477db3;
  color: white; /* Das wird die Schriftfarbe für die gesamte Karte setzen */
  border-radius: 10px; /* Abgerundete Ecken */
  border: 2px solid black; /* Schwarzer Rand */
  overflow: hidden; /* Dies stellt sicher, dass untergeordnete Elemente auch die abgerundeten Ecken respektieren */
}

.v-card-title {
  font-size: 24px;
  text-align: center;
  font-family: "Praline-Regular", sans-serif;
}

.v-list,
.v-list-item,
.v-list-item-group,
.v-list-item-content {
  background-color: #477db3; /* Setzt den Hintergrund für diese Elemente */
  color: white; /* Setzt die Schriftfarbe für diese Elemente */
}

/* Alle Textinhalte innerhalb der v-card verwenden die Praline-Schriftart */
.v-card * {
  font-family: "Praline-Regular", sans-serif;
}

.pr-4 {
  padding-right: 1rem;
}

.v-list-item-title {
  white-space: normal;
}

.hover-icon {
  cursor: pointer;
  margin-right: 0.3rem;
  width: 24px;
  height: 24px;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>

<script>
export default {
  name: "LeaderboardPage",
  data() {
    return {
      songs: [],
      hoveredIndex: null,
      currentPlayingSong: null,
      audioElements: {}, // Store audio elements by song.id
    };
  },

  async mounted() {
    const songs = await this.$axios.get(
      "https://strapi.voiceflip.ai/api/zeck/find"
    );


    this.songs = songs.data;
  },

  methods: {
    togglePlayPause(songId) {
      // If there's a song playing and it's not the one clicked
      if (this.currentPlayingSong && this.currentPlayingSong !== songId) {
        this.audioElements[this.currentPlayingSong].pause();
      }

      // If the currently playing song is the one clicked
      if (this.currentPlayingSong === songId) {
        this.audioElements[this.currentPlayingSong].pause(); // Pause the song
        this.audioElements[this.currentPlayingSong].currentTime = 0; // Reset the song's playback time to the beginning
        this.currentPlayingSong = null;
        return;
      }

      if (!this.audioElements[songId]) {
        const audio = new Audio(
          this.songs.find((song) => song.id === songId).song[0].url
        );
        this.audioElements[songId] = audio;
      }

      this.audioElements[songId].play();
      this.currentPlayingSong = songId;
    },

    handleMouseEnter(index) {
      this.hoveredIndex = index;
    },

    handleMouseLeave() {
      this.hoveredIndex = null;
    },
  },
};
</script>
