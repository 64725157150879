<template>
  <span
    class="stepbutton"
    :class="{ active: value, highlight: highlight }"
    @click="changeValue"
  >
    <span class="number">{{number}}</span>

  </span>
</template>

<script>
import { watch } from "vue";
export default {
  name: "stepbutton",
  props: ["value", "highlight", "number"],
  methods: {
    changeValue() {
      this.$emit("input", !this.value);
    },
  },
  computed: {
    bar() {
      return Math.floor((this.number - 1) / 4) + 1;
    },
    subNumber() {
      return ((this.number - 1) % 4) + 1;
    },
  },
};
</script>

<style scoped>
.number{
  display: block;
}
.stepbutton {
  text-align: center; /* Aligns the text to the right */
  vertical-align: top; /* Aligns the text to the top */
  line-height: normal; /* Resets the line height */
  padding: 5px 6px 6px 6px; /* Adjusts the position of the text within the button */
  border-width: 0px 1px 4px 1px;
  box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 1),
    inset 0px 0px 0px 3px rgba(0, 0, 0, 0.1);
  background-color: #77A0C8;
  color: white;
text-transform: uppercase;
  display: inline-block;
  width: 57px;
  height: 48px;
  border-radius: 5px;
  cursor: pointer;
  margin: 8px 0px;
  font-size: 13px;
  font-family: "URW-Bold", sans-serif;
  outline: 3px solid #3D3D3D;

  position: relative;
}

.stepbutton.active {
  border-bottom-width: 1px;
  box-shadow: 0px 1px 0px 1px rgba(0, 0, 0, 0.3),
    inset 0px 0px 0px 3px rgba(0, 0, 0, 0.1);
  color: black;

  display: inline-block;

  cursor: pointer;
  margin: 8px 0px;
}

.bottom-text {
  text-transform: uppercase;
  position: absolute;
  bottom: 6px;
  left: 6px;
  border: 1px solid white;
  border-radius: 4px;
  font-size: 6px;

  color: white;
  padding: 1px 2px 1px 2px;
}
.stepbutton.active .bottom-text {
  border: 1px solid #C05A56;

  color: #C05A56;
}
</style>

