<template>
  <svg
    width="34"
    height="50"
    class="knob"
    @mousedown.prevent="activate"
    @touchstart.prevent="activate"
  >
    <defs>
      <linearGradient id="myGradient" x1="0%" y1="0%" x2="100%" y2="0%">
        <stop offset="0%" style="stop-color: #9fa5b3; stop-opacity: 1" />
        <stop offset="100%" style="stop-color: #feffff; stop-opacity: 1" />
      </linearGradient>
    </defs>
    <!-- <circle class="shadow" cx="17" cy="17" r="16"></circle> -->
    <circle
      class="mainCircle"
      cx="17"
      cy="15"
      r="13"
      :class="{ active: active }"
      fill="url(#myGradient)"
      stroke="#131315"
      stroke-width="3"
      transform="rotate(270 17 15)"
    />
    <line x1="17" y1="9" x2="17" y2="3" :transform="knobRotation" />
    <text x="17" y="40">{{ label }}</text>
  </svg>
</template>

<script>
export default {
  name: "Knob",
  filters: {
    round(val) {
      return Math.floor(val * 20) / 20;
    },
  },
  props: ["max", "min", "value", "step", "label"],
  data() {
    return {
      internalValue: this.mapNumber(this.value, this.min, this.max, 0, 100),
      active: false,
      initialX: undefined,
      initialY: undefined,
      initialDragValue: undefined,
      shiftPressed: false,
    };
  },
  computed: {
    knobRotation() {
      const rotation = this.mapNumber(this.internalValue, 0, 100, 0, 270) - 135;
      // console.log(this.internalValue)
      // console.log("internalValue:", this.internalValue);
      // console.log("rotation:", rotation);
      return `rotate( ${rotation} 17 15)`;
    },
  },
  watch: {
    value(newVal) {
      this.internalValue = this.mapNumber(newVal, this.min, this.max, 0, 100);
    },
  },
  created() {
    document.addEventListener("keydown", (e) => {
      if (e.key === "Shift") this.shiftPressed = true;
    });
    document.addEventListener("keyup", (e) => {
      if (e.key === "Shift") this.shiftPressed = false;
    });
  },
  methods: {
    mapNumber(value, inputmin, inputmax, rangemin, rangemax) {
      const newRangeMax = parseFloat(rangemax);
      const newRangeMin = parseFloat(rangemin);
      const newInputMax = parseFloat(inputmax);
      const newInputMin = parseFloat(inputmin);
      const result =
        ((value - inputmin) * (newRangeMax - newRangeMin)) /
          (newInputMax - newInputMin) +
        newRangeMin;
      return result;
    },
    activate(event) {
      this.initialX = event.pageX || event.changedTouches[0].pageX;
      this.initialY = event.pageY || event.changedTouches[0].pageY;
      this.active = true;
      this.initialDragValue = this.internalValue;
      document.onmouseup = this.deactivate;
      document.addEventListener("touchend", this.deactivate);
      document.onmousemove = this.dragHandler;
      document.addEventListener("touchmove", this.dragHandler);
    },
    dragHandler(e) {
      const xLocation = e.pageX || e.changedTouches[0].pageX;
      const yLocation = e.pageY || e.changedTouches[0].pageY;
      if (
        Math.abs(xLocation - this.initialX) >
        Math.abs(yLocation - this.initialY)
      ) {
        if (this.shiftPressed) {
          this.internalValue =
            this.initialDragValue + (xLocation - this.initialX) / 10;
        } else {
          this.internalValue =
            this.initialDragValue + (xLocation - this.initialX) / 2;
        }
      } else if (this.shiftPressed) {
        this.internalValue =
          this.initialDragValue + (this.initialY - yLocation) / 10;
      } else {
        this.internalValue =
          this.initialDragValue + (this.initialY - yLocation) / 2;
      }
      if (this.internalValue > 100) this.internalValue = 100;
      if (this.internalValue < 0) this.internalValue = 0;
      if (Number.isNaN(this.internalValue))
        this.internalValue = this.initialDragValue;
      this.$emit(
        "update:value",
        Math.round(
          this.mapNumber(this.internalValue, 0, 100, this.min, this.max)
        )
      );
    },
    deactivate() {
      document.onmouseup = undefined;
      document.onmousemove = undefined;
      document.removeEventListener("touchmove", this.dragHandler);
      document.removeEventListener("touchend", this.deactivate);
      this.active = false;
    },
  },
};
</script>

<style scoped>
.knob {
  margin-top: 4px;
  user-select: none;
}
circle.mainCircle {
  stroke: #131315;
  stroke-width: 3px;
}

circle.mainCircle.active {
  fill: #9fa5b3;
}

circle.shadow {
  fill: #111214;
}

line {
  stroke: #07080a;
  stroke-width: 2px;
}

text {
  font-size: 8px;
  font-weight: 900;
  text-anchor: middle;
  fill: white;
}
</style>

