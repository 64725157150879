<template>
  <div class="image-wrapper">
    <v-img
      :src="imageSrc"
      :class="mobile ? 'containermobile' : 'container'"
      @click="redirectToPlay"
    ></v-img>
  </div>
</template>

<script>
import { useDisplay } from "vuetify";

export default {
  name: "PlayPage",
  data() {
    return {
      imageSrc:
        "https://fabiansbucket.s3.eu-central-1.amazonaws.com/zeck/Helpscreen-5.png",
    };
  },
  computed: {
    mobile() {
      const { mobile } = useDisplay();
      return mobile.value;
    },
  },
  methods: {
    redirectToPlay() {
      this.$router.push("/play");
    },
  },
};
</script>

<style scoped>
.image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.container {
  width: 78%;
  height: 78%;
}

.containermobile {
  /* Add mobile-specific styles here if needed. Adjust the width and height if required. */
}

.v-zigzag {
  background-image: linear-gradient(45deg, #ec173a 5px, transparent 0px),
    linear-gradient(135deg, #ec173a 5px, green 0px);
  background-color: #ec173a;
  background-position: right top;
  background-repeat: repeat-y;
  background-size: 10px 10px;
}
</style>
