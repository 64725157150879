<template>
  <div id="app">
    <!-- <v-card-title class="headline">zeck</v-card-title> -->

    <router-view></router-view>
    <!-- <div class="bg"></div> -->
  </div>
</template>

<script>
import Machine from "./components/Machine.vue";

export default {
  name: "App",
  components: {
    Machine,
  },
};
</script>

<style>
#title {
  font-size: 30px;
  color: #ffffff;
  font-weight: bold;
}

#title .smaller {
  font-size: 12px;
  font-weight: normal;
}
html,
body {
  height: 100%;
}

#app {
  height: 100%;
}
html {
  position: fixed;
  overflow: hidden;
}
body {
  font-family: BlinkMacSystemFont, sans-serif;
  user-select: none;
  /* background-color: #181818; */
  background-image: url(https://d4fm0ljlq6ko4.cloudfront.net/clouds_1abb8c9768.png?updated_at=2023-08-21T13:53:04.996Z);
  background-size: cover;
  width: 100vw;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

.bg {
  position: fixed;
  pointer-events: none;
  top: -50%;
  left: -50%;
  right: -50%;
  bottom: -50%;
  width: 200%;
  height: 200vh;
  background: transparent
    url("http://assets.iceable.com/img/noise-transparent.png") repeat 0 0;
  background-repeat: repeat;
  animation: bg-animation 0.2s infinite;
  opacity: 1;
  visibility: visible;
}

@keyframes bg-animation {
  0% {
    transform: translate(0, 0);
  }
  10% {
    transform: translate(-5%, -5%);
  }
  20% {
    transform: translate(-10%, 5%);
  }
  30% {
    transform: translate(5%, -10%);
  }
  40% {
    transform: translate(-5%, 15%);
  }
  50% {
    transform: translate(-10%, 5%);
  }
  60% {
    transform: translate(15%, 0);
  }
  70% {
    transform: translate(0, 10%);
  }
  80% {
    transform: translate(-15%, 0);
  }
  90% {
    transform: translate(10%, 5%);
  }
  100% {
    transform: translate(5%, 0);
  }
}

.headline {
  font-size: 40px !important;
  position: absolute;
  color: white;
  font-family: "Praline-Regular", sans-serif;
}
</style>
