<template>
  <span
    class="stepbutton"
    :class="{ active: value, highlight: highlight }"
    @click="changeValue"
  >
    <span class="number">{{ number }}</span>
    <!-- <span class="bottom-text">{{ bar }}.{{ subNumber }}</span> -->
  </span>
</template>

<script>
import { watch } from "vue";
export default {
  name: "stepbutton",
  props: ["value", "highlight", "number"],
  methods: {
    changeValue() {
      this.$emit("input", !this.value);
    },
  },

  computed: {
    bar() {
      return Math.floor((this.number - 1) / 4) + 1;
    },
    subNumber() {
      return ((this.number - 1) % 4) + 1;
    },
  },
};
</script>

<style scoped>
.stepbutton {
  text-align: right; /* Aligns the text to the right */
  vertical-align: top; /* Aligns the text to the top */
  line-height: normal; /* Resets the line height */
  padding: 6px 8px 6px 6px; /* Adjusts the position of the text within the button */
  border-width: 0px 1px 4px 1px;
  box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0), inset 0px 0px 0px 3px #3D3D3D;
  background-color: #BAD1E7;
  color: #95b4c5;
  display: inline-block;
  width: 57px;
  height: 48px;
  border-radius: 5px;
  cursor: pointer;
  margin: 8px 0px;
  font-size: 16px;
  font-family: "ComicNeue-Bold", sans-serif;
  position: relative;

  
}

.highlight.active {
  /* color: #36B269!important; */
  box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.3), inset 0px 0px 0px 3px #36b269 !important;
}

.stepbutton.active {
  border-bottom-width: 0px;
  box-shadow: 0px 1px 0px 1px rgba(0, 0, 0, 0.1), inset 0px 0px 0px 3px #ffffff;

  color: #ffffff;

  display: inline-block;

  cursor: pointer;
  margin: 8px 0px;
}

.bottom-text {
  text-transform: uppercase;
  position: absolute;
  bottom: 6px;
  left: 6px;
  border: 1px solid white;
  border-radius: 4px;
  font-size: 7px;

  color: white;
  padding: 1px 2px 1px 2px;
}
.stepbutton.active .bottom-text {
  border: 1px solid #c05a56;

  color: #c05a56;
}
</style>

