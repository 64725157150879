<template>

  <machine :class="mobile ? 'containermobile' : 'container'"></machine>
</template>

<script>
import Machine from "./Machine.vue";
import { useDisplay } from "vuetify";

export default {
  name: "PlayPage",
  components: {
    Machine,
  },
  computed: {
    mobile() {
      const { mobile } = useDisplay();

      return mobile.value;
    },
  },

  mounted() {
  },
};
</script>

<style scoped>
.container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  
}

.containermobile {

}

/*** vertical zig-zag ****/
.v-zigzag {
  /* background: linear-gradient(150deg, #30323f 20%, #0c0c0e 60%) */
  /* derecha */
  background-image: linear-gradient(45deg, #ec173a 5px, transparent 0px),
    linear-gradient(135deg, #ec173a 5px, green 0px);
  background-color: #ec173a;
  background-position: right top;
  background-repeat: repeat-y;
  background-size: 10px 10px;
}
</style>