<template>
  <div id="mainMachine">
    <v-dialog v-model="dialog" width="auto">
      <v-card
        color="#477db3"
        style="border-radius: 10px; border: 3px solid #3d3d3d"
      >
        <v-card-title style="text-align: center; color: white"
          >your version</v-card-title
        >
        <v-card-text>
          <audio v-if="audioURL" :src="audioURL" controls></audio>
        </v-card-text>
        <v-card-actions>
          <v-btn style="color: white" class="text-none" @click="dialog = false"
            >try again</v-btn
          ><v-spacer></v-spacer
          ><v-btn style="color: white" class="text-none" @click="send2Zeck"
            >send to zeck</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="uploadDialog" width="auto">
      <v-card
        color="#477db3"
        style="border-radius: 10px; border: 3px solid #3d3d3d"
      >
        <v-card-title style="text-align: center; color: white"
          >send your recording to zeck</v-card-title
        >
        <v-card-text>
          <v-text-field
            persistent-placeholder
            persistent-hint
            v-model="fanName"
            label="Your fantasy artist name"
            color="white"
            variant="outlined"
          ></v-text-field>
          <v-text-field
            persistent-placeholder
            persistent-hint
            v-model="fanEmail"
            label="Your email"
            color="white"
            variant="outlined"
          ></v-text-field>
        </v-card-text>
        <v-card-subtitle style="color: white; white-space: normal"
          >I hereby agree that zeck may contact me by email. I can revoke this
          consent at any time.</v-card-subtitle
        >
        <v-card-actions>
          <v-btn style="color: white" class="text-none" @click="backDialog"
            >back</v-btn
          ><v-spacer></v-spacer
          ><v-btn
            v-if="!lastLoader"
            style="color: white"
            class="text-none"
            @click="actualUpload"
            >send</v-btn
          ><v-btn v-if="lastLoader" style="color: white" class="text-none"
            >sending...</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row
      ><v-col cols="12"
        ><v-card
          max-width="500"
          class="mx-auto mainback three-d-card"
          elevation="0"
        >
          <!-- <v-card
          max-width="500"
          class="mx-auto mainback "
          :class="{ 'three-d-card': !mobile }"
          elevation="0"
        > -->
          <v-card-title
            class="mx-auto"
            style="
              background-color: #90b3d4;
              border-radius: 50px;
              padding: 5px 10px 0px 10px;
              max-width: 80%;
              margin-top: 30px;
            "
          >
            <v-row
              ><v-col cols="3" class="d-flex justify-center"
                ><knob
                  @update:value="speed = $event"
                  label="Speed"
                  min="125"
                  max="170"
                  :value="speed"
                ></knob></v-col
              ><v-col cols="3" class="d-flex justify-center"
                ><knob
                  label="Reverb"
                  min="0"
                  max="100"
                  @update:value="reverb = $event"
                  :value="reverb"
                ></knob></v-col
              ><v-col cols="3" class="d-flex justify-center"
                ><knob
                  label="Cutoff"
                  min="0"
                  max="100"
                  @update:value="cutoff = $event"
                  :value="cutoff"
                ></knob></v-col
              ><v-col cols="3" class="d-flex justify-center"
                ><knob
                  label="Rain"
                  min="0"
                  max="100"
                  @update:value="rain = $event"
                  :value="rain"
                ></knob></v-col
            ></v-row>
          </v-card-title>
          <!-- <v-card-title
            >
            <div id="knobTitles">
      <li class="knobLabel">Gain</li>
      <li class="knobLabel">Pitch</li>
      <li class="knobLabel">Length</li>
      <li class="knobLabel">Env</li>
      <li class="knobLabel">Noise</li>
      <li class="knobLabel">Mute</li>
    </div>

            <led
              v-for="(step, i) in stepCount"
              :key="`stepCount-${i}`"
              :active="i == currentStep"
            ></led> 
            </v-card-title
          > -->
          <!-- <machine-button @click="randomizeDrums">Random Drums</machine-button>
      <machine-button @click="clearSteps">Clear all</machine-button> -->
          <!-- <span class="knobLabel">Tempo</span> -->
          <!-- <knob
                    v-model="tempo"
                    min="60"
                    max="220"
                    style="position: relative; top: 31px"
                  /> -->
          <v-card-title></v-card-title
          ><v-row>
            <!-- <v-col cols="3"
              ><v-card-title
                ><div id="controls">
                  <machine-button-start
                    v-if="!playing"
                    color="#1B1C1C"
                    :pressed="playing"
                    @click="play_button"
                    >Start</machine-button-start
                  >
                  <machine-button-start
                    v-if="playing"
                    color="#1B1C1C"
                    :pressed="playing"
                    @click="stop_button"
                    >Stop</machine-button-start
                  >
                  <machine-button-start
                    color="#1B1C1C"
                    :pressed="playing"
                    @click="clearSteps"
                    >Clear</machine-button-start
                  >
                 
                </div>
              </v-card-title></v-col
            > -->
            <v-col
              cols="12"
              style="background-color: transparent; padding-bottom: 0px"
            >
              <div class="parent">
                <div class="rectangle mx-auto">
                  <!-- <video ref="video" autoplay playsinline></video> -->
                </div>
                <div class="circle">
                  <div class="rectanglemini">
                    <div class="bpm" :style="{ color: bpmColor }">
                      {{ led }}
                    </div>
                  </div>
                </div>
              </div></v-col
            >
            <!-- <v-col cols="3" style="background-color: orange"
              ><v-card-title style="text-align: right" class="justify-center"
                >Knobs
              </v-card-title></v-col
            
            > -->
          </v-row>
          <!-- <span class="knobLabel">Presets:</span> -->
          <!-- <machine-button
            v-for="(preset, k) in presets"
            :key="`presets-${k}`"
            color="#D8BCB7"
            @click="loadPreset(preset)"
            >{{ preset.name }}</machine-button
          > -->
          <v-card-title style="padding-bottom: 0px; padding-top: 30px">
            <v-card elevation="0" color="transparent"
              ><v-row style="padding: 15px">
                <v-col cols="4" style="padding-bottom: 0px; padding-top: 0px">
                  <v-row
                    ><v-col
                      style="padding-left: 3px; padding-right: 3px"
                      cols="12"
                      ><div class="section">
                        <p style="margin: 0">Drumbeat</p>
                      </div>
                    </v-col></v-row
                  ></v-col
                ><v-col
                  cols="7"
                  style="
                    margin-left: 26px;
                    padding-bottom: 0px;
                    padding-left: 3px;
                    padding-right: 3px;
                    padding-top: 0px;
                  "
                >
                  <v-row
                    ><v-col
                      style="padding-left: 13px; padding-right: 13px"
                      cols="12"
                      ><div class="section">
                        <p style="margin: 0">Instruments</p>
                      </div>
                    </v-col></v-row
                  ></v-col
                ></v-row
              >

              <!-- <machine-button
        color="#D8BCB7"
        :pressed="mutes[i]"
        style="float: right;"
        @click="mutes[i] = !mutes[i]">m</machine-button> -->
            </v-card></v-card-title
          >
          <v-card-title style="padding-bottom: 0px; padding-top: 0px">
            <v-card elevation="0" color="transparent"
              ><v-row style="padding: 15px">
                <v-col cols="4" style="padding-bottom: 0px; padding-top: 0px">
                  <v-row
                    ><v-col
                      v-for="(step, j) in sections.slice(0, 3)"
                      :key="`instrumentCount-${j}`"
                      cols="4"
                      class="d-flex justify-center"
                    >
                      <step-button-small-section
                        :number="step.short"
                        :value="sections.slice(0, 3)[j].id == selectedSection"
                        :track="sections.slice(0, 3)[j].id"
                        @updateSection="handleSection"
                        @click="
                          loadPreset(
                            this.presets[sections.slice(0, 3)[j].id - 1]
                          )
                        "
                      ></step-button-small-section> </v-col></v-row></v-col
                ><v-col
                  cols="7"
                  style="
                    margin-left: 26px;
                    padding-bottom: 0px;
                    padding-left: 3px;
                    padding-right: 3px;
                    padding-top: 0px;
                  "
                >
                  <v-row
                    ><v-col
                      v-for="(step, j) in audioTracks"
                      :key="`instrumentCount-${j}`"
                      cols="3"
                      class="d-flex justify-center"
                    >
                      <step-button-small
                        :number="step.name"
                        :channel="j + 1"
                        :value="audioTracks[j].active"
                        @input="(val) => (audioTracks[j].active = val)"
                        @setAudioVolume="setAudioVolume"
                      ></step-button-small> </v-col></v-row></v-col
              ></v-row>

              <!-- <machine-button
        color="#D8BCB7"
        :pressed="mutes[i]"
        style="float: right;"
        @click="mutes[i] = !mutes[i]">m</machine-button> -->
            </v-card></v-card-title
          >
          <v-card-title style="padding-bottom: 0px; padding-top: 0px">
            <v-card
              style="background-color: transparent"
              elevation="0"
              color="transparent"
              ><v-row style="padding: 15px">
                <v-col cols="4" style="padding-bottom: 0px; padding-top: 0px">
                  <v-row
                    ><v-col
                      v-for="(step, j) in sections.slice(3, 6)"
                      :key="`instrumentCount-${j}`"
                      cols="4"
                      class="d-flex justify-center"
                    >
                      <step-button-small-section
                        :number="step.short"
                        :value="sections.slice(3, 6)[j].id == selectedSection"
                        :track="sections.slice(3, 6)[j].id"
                        @updateSection="handleSection"
                        @click="
                          loadPreset(
                            this.presets[sections.slice(3, 6)[j].id - 1]
                          )
                        "
                      ></step-button-small-section> </v-col></v-row></v-col
                ><v-col
                  cols="7"
                  style="
                    margin-left: 26px;
                    padding-bottom: 0px;
                    padding-left: 3px;
                    padding-right: 3px;
                    padding-top: 0px;
                  "
                >
                  <v-row
                    ><v-col
                      v-for="(step, j) in allDrumTracks"
                      :key="`instrumentCount-${j}`"
                      cols="3"
                      class="d-flex justify-center"
                    >
                      <step-button-small-select
                        :number="step.name"
                        :value="allDrumTracks[j].track == selectedDrumTrack"
                        :track="allDrumTracks[j].track"
                        @updateDrumTrack="handleEvent"
                      ></step-button-small-select> </v-col></v-row></v-col
              ></v-row>

              <!-- <machine-button
        color="#D8BCB7"
        :pressed="mutes[i]"
        style="float: right;"
        @click="mutes[i] = !mutes[i]">m</machine-button> -->
            </v-card></v-card-title
          >
          <v-card-title>
            <v-card
              elevation="0"
              color="transparent"
              v-for="(inst, i) in 1"
              :key="i"
              ><v-card-title style="padding-left: 0px">
                <v-row>
                  <v-col cols="9" style="margin-bottom: 20px">
                    <v-row
                      ><v-col
                        cols="3"
                        class="d-flex justify-center"
                        v-for="(step, j) in stepCount"
                        :key="`instrumentCount-${j}`"
                        style="padding-bottom: 0px"
                      >
                        <step-button
                          :number="j + 1"
                          :value="pattern[selectedDrumTrack][j].active"
                          :highlight="currentStep == j"
                          @input="
                            (val) =>
                              (pattern[selectedDrumTrack][j].active = val)
                          "
                        ></step-button> </v-col></v-row
                  ></v-col>
                  <v-col cols="3" style="margin-bottom: 20px"
                    ><v-row
                      ><v-col
                        cols="12"
                        class="justify-end d-flex"
                        style="padding-bottom: 0px"
                        ><router-link to="/help">
                          <step-button-right
                            number="Help"
                            :value="1"
                          ></step-button-right
                        ></router-link> </v-col></v-row
                    ><v-row
                      ><v-col
                        cols="12"
                        class="justify-end d-flex"
                        style="padding-bottom: 0px"
                      >
                        <machine-button-start
                          color="#1B1C1C"
                          :pressed="playing"
                          @click="clearSteps"
                          >Clear</machine-button-start
                        >
                      </v-col></v-row
                    ><v-row
                      ><v-col
                        cols="12"
                        class="justify-end d-flex"
                        style="padding-bottom: 0px"
                      >
                        <step-button-right-outlined
                          v-if="!isRecording && this.audioBuffers.length"
                          :number="0"
                          name="REC"
                          :value="isRecording"
                          @input="recordingButton"
                        ></step-button-right-outlined>
                        <step-button-right-outlined
                          v-if="!isRecording && !this.audioBuffers.length"
                          name="WAIT"
                        ></step-button-right-outlined>
                        <step-button-right-outlined
                          v-if="isRecording"
                          :number="0"
                          name="END"
                          :value="isRecording"
                          @input="recordingStopButton"
                        ></step-button-right-outlined> </v-col></v-row
                    ><v-row
                      ><v-col
                        cols="12"
                        class="justify-end d-flex"
                        style="padding-bottom: 0px"
                      >
                        <machine-button-start
                          v-if="!playing && this.audioBuffers.length"
                          color="#1B1C1C"
                          :pressed="playing"
                          @click="play_button"
                          >Start</machine-button-start
                        >
                        <machine-button-start
                          v-if="!this.audioBuffers.length"
                          color="#1B1C1C"
                          :pressed="playing"
                          @click="play_button"
                          ><v-progress-circular
                            size="20"
                            indeterminate
                          ></v-progress-circular
                        ></machine-button-start>
                        <machine-button-start
                          v-if="playing"
                          color="#1B1C1C"
                          :pressed="playing"
                          @click="stop_button"
                          >Stop</machine-button-start
                        >

                        <!-- <step-button-right
                          number="ON/OFF"
                          :value="1"
                        ></step-button-right> -->
                      </v-col></v-row
                    ></v-col
                  ></v-row
                ></v-card-title
              >

              <!-- <machine-button
        color="#D8BCB7"
        :pressed="mutes[i]"
        style="float: right;"
        @click="mutes[i] = !mutes[i]">m</machine-button> -->
            </v-card></v-card-title
          >
        </v-card></v-col
      ></v-row
    >
  </div>
</template>

<script>
import _ from "lodash";
import StepButton from "./StepButton.vue";
import StepButtonRightOutlined from "./StepButtonRightOutlined.vue";
import StepButtonRight from "./StepButtonRight.vue";
import StepButtonSmall from "./StepButtonSmall.vue";
import StepButtonSmallSelect from "./StepButtonSmallSelect.vue";
import StepButtonSmallSection from "./StepButtonSmallSection.vue";
import { useDisplay } from "vuetify";
import Recorder from "recorder-js"; // Adjust this import based on your setup

import Knob from "./Knob.vue";
import Led from "./Led.vue";
import MachineButton from "./MachineButton.vue";
import MachineButtonStart from "./MachineButtonStart.vue";
import presets from "../presets";
import kick from "../assets/kick_full.mp3";
import snare from "../assets/snare_wet.mp3";
import hat_closed from "../assets/hat_closed.mp3";
import hat_open from "../assets/schelle.mp3";
import impulseResponsePath from "../assets/CHSTJ21_MLA.wav";

let audioContext;

if (window.AudioContext) {
  audioContext = new AudioContext();
} else {
  // eslint-disable-next-line no-undef, new-cap
  audioContext = new webkitAudioContext();
}

const bufferSize = 2 * audioContext.sampleRate;
const noiseBuffer = audioContext.createBuffer(
  1,
  bufferSize,
  audioContext.sampleRate
);
const output = noiseBuffer.getChannelData(0);

for (let i = 0; i < bufferSize; i += 1) {
  output[i] = Math.random() * 2 - 1;
}

export default {
  name: "Machine",
  components: {
    StepButton,
    StepButtonRight,
    StepButtonRightOutlined,
    StepButtonSmall,
    StepButtonSmallSelect,
    StepButtonSmallSection,
    Knob,
    Led,
    MachineButton,
    MachineButtonStart,
    StepButtonSmallSection,
  },
  data() {
    return {
      lastLoader: false,
      fanName: null,
      fanEmail: null,
      dialog: false,
      uploadDialog: false,
      isRecording: false,
      recorder: null,
      audioBlob: null,
      audioURL: null,
      loadbutton: false,
      kick: null,
      snare: null,
      open_hat: null,
      closed_hat: null,
      masterReverb: null,
      masterGain: null,
      masterGainWet: null,
      buffer: null,
      audio_rain: null,
      audio1: null,
      audio1Started: false,
      audio2: null,
      audio2Started: false,
      audio3: null,
      audio3Started: false,
      audio4: null,
      audio4Started: false,
      audio1Gain: null,
      audio1GainWet: null,

      audio2Gain: null,
      audio2GainWet: null,

      audio3Gain: null,
      audio3GainWet: null,
      audio_rain_gain: null,
      audio4Gain: null,
      audio4GainWet: null,

      parentTrack: null,
      audio1Volume: 1,
      audio2Volume: 1,
      audio3Volume: 1,
      audio4Volume: 1,
      audioRainVolume: 0,
      audio1ReverbLevel: 0,
      audio2ReverbLevel: 0,
      audio3ReverbLevel: 0,
      audio4ReverbLevel: 0,
      initialSchnips: false,

      schnips: false,
      audioTracks: [
        { name: "VX", id: 1, active: true },
        { name: "BA", id: 2, active: true },
        { name: "GT", id: 3, active: true },
        { name: "DR", id: 4, active: true },
      ],
      allDrumTracks: [
        { name: "KD", track: 0 },
        { name: "SD", track: 1 },
        { name: "CH", track: 2 },
        { name: "OH", track: 3 },
      ],
      sections: [
        { name: "Intro", short: "1", id: 1 },
        { name: "Verse 1", short: "2", id: 2 },
        { name: "Chorus", short: "3", id: 3 },
        { name: "Verse 2", short: "4", id: 4 },
        { name: "Bridge 1", short: "5", id: 5 },
        { name: "Bridge 2", short: "6", id: 6 },
      ],
      selectedDrumTrack: 0,
      selectedSection: 1,
      ledColor: "green", // Set the initial color
      led: 148,
      speed: 148,
      originalBPM: 148, // replace with the known original BPM of your audio file
      reverb: 0,
      cutoff: 100,
      rain: 0,
      audioContext: new AudioContext(),
      audioBuffers: [],
      audio: null,
      pattern: [],
      stepCount: 16,
      instruments: [],
      currentStep: 0,
      secondsPerStep: 0,
      lastScheduledTime: 0,
      nextStepTime: 0,
      mutes: [],
      playing: false,
      tempo: 148,
      audioTime: undefined,
      presets,
    };
  },

  computed: {
    instrumentCount() {
      return this.instruments.length;
    },
    mobile() {
      const { mobile } = useDisplay();

      return mobile.value;
    },
  },
  watch: {
    // led() {
    //   setTimeout(() => {
    //     this.bpmColor = "#353638";
    //     this.led = 888;
    //   }, 10000);
    // },

    selectedSection(newVal) {
      this.schnips = false;

      // if (newVal == 1) {
      //   this.audio1.buffer = this.audioBuffers[7];
      //   this.audio2.buffer = this.audioBuffers[6];
      //   this.audio3.buffer = this.audioBuffers[5];
      //   this.audio4.buffer = this.audioBuffers[4];
      // } else if (newVal == 2) {
      //   this.audio1.buffer = this.audioBuffers[8];
      //   this.audio2.buffer = this.audioBuffers[9];
      //   this.audio3.buffer = this.audioBuffers[10];
      //   this.audio4.buffer = this.audioBuffers[11];
      // } else if (newVal == 3) {
      //   this.audio1.buffer = this.audioBuffers[12];
      //   this.audio2.buffer = this.audioBuffers[13];
      //   this.audio3.buffer = this.audioBuffers[14];
      //   this.audio4.buffer = this.audioBuffers[15];
      // } else if (newVal == 4) {
      //   this.audio1.buffer = this.audioBuffers[16];
      //   this.audio2.buffer = this.audioBuffers[17];
      //   this.audio3.buffer = this.audioBuffers[18];
      //   this.audio4.buffer = this.audioBuffers[19];
      // }
    },

    selectedDrumTrack(newVal) {
      // this.loadPreset(this.presets[newVal]);
    },

    speed(newVal) {
      // this.bpmColor = "#f95659";
      this.setPlaybackRate();

      this.led = newVal;
    },
    reverb(newVal) {
      this.setAudioReverb();
      this.led = newVal;
    },
    cutoff(newVal) {
      this.setFilterFrequencyFromKnobValue(this.cutoff); // You can adjust this value as needed.

      this.led = newVal;
    },
    rain(newVal) {
      this.setRain();
      this.led = newVal;
    },
  },
  async mounted() {
    // CREATE TRACKS
    this.audio1 = this.audioContext.createBufferSource();
    this.audio2 = this.audioContext.createBufferSource();
    this.audio3 = this.audioContext.createBufferSource();
    this.audio4 = this.audioContext.createBufferSource();

    const playbackRate = this.speed / this.originalBPM;
    this.audio1.playbackRate.setValueAtTime(
      playbackRate,
      this.audioContext.currentTime
    );
    this.audio2.playbackRate.setValueAtTime(
      playbackRate,
      this.audioContext.currentTime
    );
    this.audio3.playbackRate.setValueAtTime(
      playbackRate,
      this.audioContext.currentTime
    );
    this.audio4.playbackRate.setValueAtTime(
      playbackRate,
      this.audioContext.currentTime
    );

    this.audio_rain = this.audioContext.createBufferSource();

    // INIT REVERB

    const response = await fetch(impulseResponsePath);
    const data = await response.arrayBuffer();

    this.buffer = await this.audioContext.decodeAudioData(data);
    // 1. Create a BiquadFilterNode.
    this.masterLowpassFilter = this.audioContext.createBiquadFilter();

    // 2. Configure it as a lowpass filter.
    this.masterLowpassFilter.type = "lowpass";

    // 3. Set its frequency (the cutoff frequency). For example, setting it to 1000Hz.
    this.masterLowpassFilter.frequency.value = 22000;

    // Create master reverb
    this.masterReverb = this.audioContext.createConvolver();
    this.masterReverb.buffer = this.buffer;

    // Create master gain nodes
    this.masterGain = this.audioContext.createGain();
    this.masterGainWet = this.audioContext.createGain();

    // Set initial gain values
    this.masterGain.gain.value = 1;
    this.masterGainWet.gain.value = 0; // Assuming reverb is a value between 0-100

    // CREATE AUDIO TRACK GAINS
    this.audio1Gain = this.audioContext.createGain();
    this.audio2Gain = this.audioContext.createGain();
    this.audio3Gain = this.audioContext.createGain();
    this.audio4Gain = this.audioContext.createGain();
    this.audio_rain_gain = this.audioContext.createGain();

    // CONNECT AUDIO TRACKS
    this.audio1.connect(this.audio1Gain);
    this.audio2.connect(this.audio2Gain);
    this.audio3.connect(this.audio3Gain);
    this.audio4.connect(this.audio4Gain);

    // CONNECT AUDIA GTACK GAINS WITH AUDIO CONTEXT
    this.audio1Gain.connect(this.masterGain);
    this.audio2Gain.connect(this.masterGain);
    this.audio3Gain.connect(this.masterGain);
    this.audio4Gain.connect(this.masterGain);

    // CONNECT AUDIA GTACK GAINS WITH AUDIO CONTEXT
    this.audio1Gain.connect(this.masterReverb);
    this.audio2Gain.connect(this.masterReverb);
    this.audio3Gain.connect(this.masterReverb);
    this.audio4Gain.connect(this.masterReverb);

    this.audio_rain_gain.connect(this.audioContext.destination);

    // SET VOLUME OF AUDIO GAINS
    this.audio1Gain.gain.value = this.audio1Volume;
    this.audio2Gain.gain.value = this.audio2Volume;
    this.audio3Gain.gain.value = this.audio3Volume;
    this.audio4Gain.gain.value = this.audio4Volume;
    this.audio_rain_gain.gain.value = this.audioRainVolume;

    // Connect everything
    this.masterGain.connect(this.masterLowpassFilter);
    this.masterReverb.connect(this.masterGainWet);
    this.masterGainWet.connect(this.masterLowpassFilter);

    this.masterLowpassFilter.connect(this.audioContext.destination);

    this.loadPreset(this.presets[0]);
    if (!window.AudioContext) this.playing = false; // Safari fix
    const audioFiles = [
      kick,
      snare,
      hat_closed,
      hat_open,
      "https://d4fm0ljlq6ko4.cloudfront.net/Zeck_STSM_Bass_eff60eff0c.mp3",
      "https://d4fm0ljlq6ko4.cloudfront.net/Zeck_STSM_Guitar_6afe9b5949.mp3",
      "https://d4fm0ljlq6ko4.cloudfront.net/Zeck_STSM_Percussion_db98abe362.mp3",
      "https://d4fm0ljlq6ko4.cloudfront.net/Zeck_STSM_Vocal_2cb37c4ced.mp3",
      "https://d4fm0ljlq6ko4.cloudfront.net/rain_fin_6ed9522013.mp3",
    ];

    Promise.all(
      audioFiles.map((file) =>
        fetch(file)
          .then((response) => {
            return response.arrayBuffer();
          })
          .then((arrayBuffer) => this.audioContext.decodeAudioData(arrayBuffer))
      )
    ).then((decodedAudioBuffers) => {
      this.audioBuffers = decodedAudioBuffers;
      this.loadbutton = false;
    });
  },
  created() {
    // Add some instruments

    for (let i = 0; i < 4; i += 1) {
      this.instruments.push({
        freq: 100,
        gain: 0.2,
        decay: 0.01,
        endPitch: 0.01,
        sineNoiseMix: 0.01,
      });
      this.mutes.push(false);
      this.randomizeDrums();
    }

    // Create empty patterns

    for (let i = 0; i < this.instrumentCount; i += 1) {
      this.pattern.push([]);
      for (let j = 0; j < this.stepCount; j += 1) {
        this.pattern[i].push({ active: false });
      }
    }

    this.updateAudioTime();
  },
  methods: {
    backDialog() {
      this.uploadDialog = false;
      this.dialog = true;
    },
    async send2Zeck() {
      this.dialog = false;
      this.uploadDialog = true;
    },
    async actualUpload() {
      if (this.fanName && this.fanEmail) {
        console.log((this.lastLoader = true));
        const name = this.fanName;
        const email = this.fanEmail;

        const formData = new FormData();
        formData.append("files", this.audioBlob, "zeck_audio");

        const res = await this.$axios.post(
          "https://strapi.voiceflip.ai/api/upload",
          formData
        );

        await this.$axios.post("https://strapi.voiceflip.ai/api/zeck", {
          artist_name: name,
          email: email,
          song: res.data[0].id,
        });

        this.uploadDialog = false;
        this.fanName = null;
        this.fanEmail = null;
        this.lastLoader = false;

        this.$router.push("/leaderboard");
      }
    },
    async recordingStopButton() {
      this.recorder.stop().then(({ blob }) => {
        this.audioBlob = blob;
        this.audioURL = URL.createObjectURL(blob);
        this.isRecording = false;
        this.playing = false;
        this.stopAndDisconnect(this.audio1, this.audio1Started);
        this.stopAndDisconnect(this.audio2, this.audio2Started);
        this.stopAndDisconnect(this.audio3, this.audio3Started);
        this.stopAndDisconnect(this.audio4, this.audio4Started);
        this.audio_rain_gain.gain.value = 0;
        this.dialog = true;
      });
    },
    async recordingButton() {
      if (this.playing) {
        // console.log("HALLO");
        const dest = this.audioContext.createMediaStreamDestination();
        this.masterLowpassFilter.connect(dest);

        const stream = dest.stream;

        this.recorder = new Recorder(this.audioContext);
        this.recorder.init(stream);
        this.recorder.start();
        this.isRecording = true;
      } else {
        this.play_button();
        const dest = this.audioContext.createMediaStreamDestination();
        this.masterLowpassFilter.connect(dest);

        const stream = dest.stream;

        this.recorder = new Recorder(this.audioContext);
        this.recorder.init(stream);
        this.recorder.start();
        this.isRecording = true;
      }
    },

    setPlaybackRate() {
      this.stopAndDisconnect(this.kick, this.audio1Started);
      this.stopAndDisconnect(this.snare, this.audio2Started);
      this.stopAndDisconnect(this.open_hat, this.audio3Started);
      this.stopAndDisconnect(this.closed_hat, this.audio4Started);
      this.schnips = false;
      if (this.audio1) {
        const playbackRate = this.speed / this.originalBPM;
        this.audio1.playbackRate.setValueAtTime(
          playbackRate,
          this.audioContext.currentTime
        );
        this.audio2.playbackRate.setValueAtTime(
          playbackRate,
          this.audioContext.currentTime
        );
        this.audio3.playbackRate.setValueAtTime(
          playbackRate,
          this.audioContext.currentTime
        );
        this.audio4.playbackRate.setValueAtTime(
          playbackRate,
          this.audioContext.currentTime
        );
      }
    },
    setFilterFrequencyFromKnobValue(knobValue) {
      // Calculate the Nyquist frequency
      let maxFrequency = this.audioContext.sampleRate / 2;

      // Map the knob value to the frequency range
      let frequency = 0 + (knobValue / 100) * maxFrequency;

      // Set the filter's frequency
      this.masterLowpassFilter.frequency.value = frequency;
    },

    setAudioVolume(message) {
      if (message.track == 1) {
        if (this.audio1Volume == 1) {
          this.audio1Volume = 0;
        } else {
          this.audio1Volume = 1;

          // const reverb_knob_mapped_value =
          //   ((this.reverb - 0) / (100 - 0)) * (1 - 0) + 0; // Using the formula

          // this.audio1ReverbLevel = reverb_knob_mapped_value;
        }

        // Update the gain value for audio1Gain
        if (this.audio1Gain) {
          this.audio1Gain.gain.value = this.audio1Volume;
        }
      } else if (message.track == 2) {
        if (this.audio2Volume == 1) {
          this.audio2Volume = 0;
        } else {
          this.audio2Volume = 1;
        }

        // Update the gain value for audio1Gain
        if (this.audio2Gain) {
          this.audio2Gain.gain.value = this.audio2Volume;
        }
      } else if (message.track == 3) {
        if (this.audio3Volume == 1) {
          this.audio3Volume = 0;
        } else {
          this.audio3Volume = 1;
        }

        // Update the gain value for audio1Gain
        if (this.audio3Gain) {
          this.audio3Gain.gain.value = this.audio3Volume;
        }
      } else if (message.track == 4) {
        if (this.audio4Volume == 1) {
          this.audio4Volume = 0;
        } else {
          this.audio4Volume = 1;
        }

        // Update the gain value for audio1Gain
        if (this.audio4Gain) {
          this.audio4Gain.gain.value = this.audio4Volume;
        }
      }
    },

    setAudioReverb() {
      const reverb_knob_mapped_value =
        ((this.reverb - 0) / (100 - 0)) * (1 - 0) + 0; // Using the formula

      this.masterGainWet.gain.value = reverb_knob_mapped_value;
    },

    setRain() {
      const reverb_knob_mapped_value =
        ((this.rain - 0) / (100 - 0)) * (1 - 0) + 0; // Using the formula

      this.audio_rain_gain.gain.value = reverb_knob_mapped_value;
    },

    handleSection(message) {
      this.selectedSection = message;
    },
    handleEvent(message) {
      this.selectedDrumTrack = message;
    },

    stop_button() {
      this.playing = false;
      this.initialSchnips = false;
      this.schnips = false;
      this.stopAndDisconnect(this.audio1, this.audio1Started);
      this.stopAndDisconnect(this.audio2, this.audio2Started);
      this.stopAndDisconnect(this.audio3, this.audio3Started);
      this.stopAndDisconnect(this.audio4, this.audio4Started);
    },
    loadPreset(preset) {
      // console.log(preset);
      const loadedPreset = _.cloneDeep(preset);
      this.pattern = preset.pattern;
      this.tempo = loadedPreset.tempo;
      this.instruments = preset.instruments;
      for (let i = 0; i < this.mutes.length; i += 1) {
        this.mutes[i] = false;
      }
    },
    randomizeSteps() {
      for (let i = 0; i < this.pattern.length; i += 1) {
        for (let j = 0; j < this.pattern[i].length; j += 1) {
          this.pattern[i][j].active = Math.random() > 0.5;
        }
      }
    },
    randomizeDrums() {
      for (const inst of this.instruments) {
        inst.freq = Math.random() * 3000 + 100;
        inst.decay = Math.random() * 0.8 + 0.01;
        inst.endPitch = Math.random() * 0.8 + 0.01;
        inst.sineNoiseMix = Math.random() * 1 + 0.01;
      }
    },
    clearSteps() {
      for (let i = 0; i < this.pattern.length; i += 1) {
        for (let j = 0; j < this.pattern[i].length; j += 1) {
          this.pattern[i][j].active = false;
        }
      }
      for (let i = 0; i < this.mutes.length; i += 1) {
        this.mutes[i] = false;
      }
    },
    createAudioBufferSource(bufferIndex) {
      const source = this.audioContext.createBufferSource();
      const buffer = this.audioBuffers[bufferIndex];

      if (!(buffer instanceof AudioBuffer)) {
        console.error(`Buffer at index ${bufferIndex} is not an AudioBuffer.`);
        return null;
      }

      source.buffer = buffer;
      return source;
    },
    stopAndDisconnect(audioNode, startedFlag) {
      if (audioNode) {
        if (startedFlag) {
          try {
            audioNode.stop();
          } catch (error) {
            console.error("Error stopping audio node:", error);
          }
        }
        audioNode.disconnect();
        audioNode = null;
      }
    },
    scheduleNote(instrument, startTime) {
      //  console.log(this.currentStep);

      if (!this.schnips) {
        // console.log(this.audioTime);
        // console.log(this.secondsPerStep);
        // console.log(this.stepCount);

        // console.log(
        //   Math.floor((this.audioTime / this.secondsPerStep) % this.stepCount)
        // );

        if (this.currentStep == 10 || this.currentStep == 11) {
          

          if (!this.initialSchnips) {
            this.audio1 = this.createAudioBufferSource(7); // Assuming index 4 is for audio1
            this.audio2 = this.createAudioBufferSource(4); // Assuming index 5 is for audio2
            this.audio3 = this.createAudioBufferSource(5); // Assuming index 6 is for audio3
            this.audio4 = this.createAudioBufferSource(6); // Assuming index 7 is for audio4
            this.audio1.connect(this.audio1Gain);
            this.audio2.connect(this.audio2Gain);
            this.audio3.connect(this.audio3Gain);
            this.audio4.connect(this.audio4Gain);

            this.audio1.start(startTime);
            this.audio2.start(startTime);
            this.audio3.start(startTime);
            this.audio4.start(startTime);

            this.audio1Started = true;
            this.audio2Started = true;
            this.audio3Started = true;
            this.audio4Started = true;
          }

          this.audio_rain = this.createAudioBufferSource(8); // Assuming index 4 is for audio1
          this.audio_rain.loop = true;

          this.audio_rain.connect(this.audio_rain_gain);
          this.audio_rain.start(startTime);
          this.schnips = true;
          this.initialSchnips = true;
        }
      }
      const playbackRate = this.speed / this.originalBPM;
      this.audio1.playbackRate.setValueAtTime(
        playbackRate,
        this.audioContext.currentTime
      );
      this.audio2.playbackRate.setValueAtTime(
        playbackRate,
        this.audioContext.currentTime
      );
      this.audio3.playbackRate.setValueAtTime(
        playbackRate,
        this.audioContext.currentTime
      );
      this.audio4.playbackRate.setValueAtTime(
        playbackRate,
        this.audioContext.currentTime
      );

      if (instrument == 0) {
        this.kick = this.createAudioBufferSource(0); // Assuming index 4 is for audio1
        this.kick.connect(this.masterGain);
        this.kick.connect(this.masterReverb);

        this.kick.start(startTime);
      } else if (instrument == 1) {
        this.snare = this.createAudioBufferSource(1); // Assuming index 4 is for audio1

        this.snare.connect(this.masterGain);
        this.snare.connect(this.masterReverb);
        this.snare.start(startTime);
      } else if (instrument == 2) {
        this.closed_hat = this.createAudioBufferSource(2); // Assuming index 4 is for audio1
        this.closed_hat.connect(this.masterGain);
        this.closed_hat.connect(this.masterReverb);
        this.closed_hat.start(startTime);
      } else if (instrument == 3) {
        this.open_hat = this.createAudioBufferSource(3); // Assuming index 4 is for audio1
        this.open_hat.connect(this.masterGain);
        this.open_hat.connect(this.masterReverb);
        this.open_hat.start(startTime);
      }
    },

    getSchedule(step, currentTime) {
      let stepTime =
        step * this.secondsPerStep +
        (currentTime - (currentTime % (this.secondsPerStep * this.stepCount)));
      if (stepTime < currentTime) {
        // skip to the next pattern if it's already too late
        stepTime += this.secondsPerStep * this.stepCount;
      }

      return stepTime;
    },
    play_button() {
      if (this.audioBuffers.length) {
        this.audioTime = 0; // Initialize the audio time to 0
        this.lastScheduledTime = 0; // Initialize the last scheduled time to 0
        this.playing = true; // Set the playing flag to true
        // audioContext.createOscillator().start(0, 0, 0.1);
        this.currentStep = 0; // Initialize the current step to 0

        this.updateAudioTime(); // Start the updateAudioTime function
      } else {
        this.loadbutton = true;
        // console.log(
        //   "audio buffers are still loading. needs progress bar here."
        // );
      }
    },
    updateAudioTime() {
      if (this.playing) {
        const LOOK_AHEAD = 0.6;
        this.secondsPerStep = 60 / this.speed / 4;
        this.audioTime = this.audioContext.currentTime;

        // Calculate the current step based on audioTime
        this.currentStep = Math.floor(
          (this.audioTime / this.secondsPerStep) % this.stepCount
        );

        // Update visuals based on this.currentStep

        for (const inst in this.pattern) {
          if (!this.mutes[inst]) {
            for (const step in this.pattern[inst]) {
              if (this.pattern[inst][step].active) {
                const schedule = this.getSchedule(step, this.audioTime);
                if (
                  schedule > 0 &&
                  schedule - this.audioTime < LOOK_AHEAD &&
                  schedule > this.lastScheduledTime
                ) {
                  this.scheduleNote(inst, schedule);
                }
              }
            }
          }
        }

        this.lastScheduledTime = this.audioTime + LOOK_AHEAD;
      }

      // Use setTimeout instead of requestAnimationFrame for better accuracy
      const nextStepTime =
        this.secondsPerStep - (this.audioTime % this.secondsPerStep);
      setTimeout(this.updateAudioTime, nextStepTime * 1000);
    },
  },
};
</script>

<style scoped>
.mainback {
  background: #477db3;
  background-image: url(https://d4fm0ljlq6ko4.cloudfront.net/flowershq_ccc6b87d78.png?updated_at=2023-08-24T19:47:06.064Z);
  background-size: contain; /* Fit entire image inside the container */
  padding: 10px;
}



/* .three-d-card:hover {
  transform: perspective(1000px) rotateX(0) rotateY(0);
} */

#title {
  font-size: 40px;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  letter-spacing: 5px;
  font-family: "Weimar-SemiBold", sans-serif;
  transform: scaleY(0.4);
}

#title.mobile-font {
  font-size: 30px; /* Or whatever smaller font size you desire */
}

#title .smaller {
  font-size: 12px;
  font-weight: normal;
}

#mainMachine {
  margin: 0 auto;
  /* height: 100%; */
  /* margin-top: 50px; */
}
@media (min-width: 376px) {
  #mainMachine {
    padding: 5px;
  }
}

@media (min-width: 376px) {
  .three-d-card {

      border: 3px solid #3d3d3d;
  border-radius: 10px;

  border-top-left-radius: 155px 10px;
  border-top-right-radius: 10px 135px;
  border-bottom-right-radius: 135px 10px;
  border-bottom-left-radius: 10px 155px;
    /* Include the styles from the "three-d-card" class here */
  }
}
#knobTitles {
  display: inline-block;
  margin-left: 6px;
}

#controls {
  text-align: left;
}

.knobLabel {
  display: inline-block;
  font-size: 12px;
  margin: 0px 7px;
  color: #666;
}

.parent {
  position: relative;
}

.rectangle {
  max-width: 90%;
  height: 110px;
  background: #90b3d4;
  border-radius: 4% / 50%;
  border: 3px solid #3d3d3d;

  overflow: hidden;
  z-index: 1; /* Setting z-index */
}

.circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 130px;
  height: 130px;
  border: 3px solid #3d3d3d;
  /* box-shadow: 0px 1px 2px 1px #18191b, inset 0px 0px 0px 0px #18191b; */
  background-color: #c4d9eb;
  border-radius: 50%;
  z-index: 9999; /* Setting a higher z-index so it's above .rectangle */
}
.rectanglemini {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 95px;
  height: 40px;
  border: 3px solid #3d3d3d;
  border-radius: 5px;

  background-color: #9e8282; /* Color of the rectangle */
}

.bpm {
  position: absolute;
  right: 0px; /* Right-aligned with some padding */
  top: 56%;
  color: #ffffff; /* Text color */
  transform: translateY(-50%);
  font-size: 35px; /* Font size */
  letter-spacing: 4px;
  font-family: "Technology-Italic", sans-serif;
}
.circle video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  filter: hue-rotate(200deg) contrast(140%) brightness(90%) saturate(150%);
  transform: translate(-50%, -50%) scaleX(-1);
}

/* .rectangle {
  position: relative;
  max-width: 90%;
  height: 136px;
  margin: 20px 0;
  background: green;
  border-radius: 50% / 10%;
  color: white;
  text-align: center;
  text-indent: .1em;
}
.rectangle:before {
  content: '';
  position: absolute;
  top: 10%;
  bottom: 10%;
  right: -5%;
  left: -5%;
  background: inherit;
  border-radius: 5% / 50%;
}
.rectangle:after {
  content: '';
  position: absolute;
  bottom: 0px;
  right: -11px;
   max-width: 90%;
  height: 136px;
  background: green;
  border-radius: 20% / 150%;        
} */

.zigzag {
  /* background: linear-gradient(150deg, #30323f 20%, #0c0c0e 60%); */
  /* derecha */
  background-color: orange;

  background-image: linear-gradient(45deg, orange 8px, transparent 0px),
    linear-gradient(135deg, orange 8px, #1b2157 0px);
  background-position: right top;
  background-repeat: repeat-y;
  background-size: 10px 10px;
}

.section {
  width: 100%;
  height: 16px;
  display: flex;
  border-radius: 3px;
  align-items: center;
  justify-content: center;
  padding-top: 3px;
  background-color: #c7dbed;
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
  font-family: "URW-Bold", sans-serif;
}
</style>
