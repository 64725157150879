export default {
    install: (app, options) => {
      app.config.globalProperties.$trackPage = (page) => {
        if (window.gtag) {
          window.gtag('config', options.gaId, {
            page_path: page,
          });
        }
      };
  
      app.config.globalProperties.$trackEvent = (action, params) => {
        if (window.gtag) {
          window.gtag('event', action, params);
        }
      };
  
      let script = document.createElement('script');
      script.async = true;
      script.src = `https://www.googletagmanager.com/gtag/js?id=${options.gaId}`;
  
      script.onload = () => {
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          window.dataLayer.push(arguments);
        }
        window.gtag = gtag;
        gtag('js', new Date());
        gtag('config', options.gaId);
      };
  
      document.head.appendChild(script);
    },
  };
  