<template>
  <span
    class="stepbutton"
    :class="{ active: value, highlight: highlight }"
    @click="changeValue(track)"
  >
    <span class="number"><img :src="opener" alt="SVG Image" /></span>
  </span>
</template>

<script>
import KD from "../assets/KD.svg";
import SD from "../assets/SD.svg";
import OH from "../assets/OH.svg";
import CH from "../assets/CH.svg";

export default {
  name: "stepbutton",
  props: ["value", "highlight", "number", "track"],
  data() {
    return {
      KD: KD, // Assuming your SVG is named star.svg and is directly inside the assets folder
      SD: SD,
      OH: OH,
      CH: CH,
      opener: null,
    };
  },
  mounted() {
    if (this.number == "KD") {
      this.opener = this.KD;
    } else if (this.number == "SD") {
      this.opener = this.SD;
    } else if (this.number == "OH") {
      this.opener = this.OH;
    } else if (this.number == "CH") {
      this.opener = this.CH;
    }
  },

  methods: {
    changeValue() {
      this.$emit("updateDrumTrack", this.track);
    },
  },
  computed: {
    bar() {
      return Math.floor((this.number - 1) / 4) + 1;
    },
    subNumber() {
      return ((this.number - 1) % 4) + 1;
    },
  },
};
</script>

<style scoped>
.stepbutton {
  display: inline-block;
  align-items: center;
  justify-content: center;
  text-align: center; /* Aligns the text to the right */
  line-height: 30px; /* Resets the line height */
  padding: 3.5px 6px 6px 6px; /* Adjusts the position of the text within the button */
  border-width: 0px 1px 4px 1px;
  box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.2),
    inset 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
  background-color: #adbdd3;
  color: black;
  min-width: 35px;
  height: 30px;
  border-radius: 5px;
  cursor: pointer;
  margin: 2px 0px;
  font-size: 12px;
  text-transform: uppercase;
  font-family: "URW-Bold", sans-serif;
  position: relative;
}

.stepbutton.active {
  border-bottom-width: 1px;
  box-shadow: 0px 1px 0px 0px rgba(250, 85, 85, 0),
    inset 0px 0px 0px 2px #3d3d3d;
  background-color: #e6f0f8;

  display: inline-block;

  cursor: pointer;
  margin: 2px 0px;
}

.bottom-text {
  text-transform: uppercase;
  position: absolute;
  bottom: 6px;
  left: 6px;
  border: 1px solid white;
  border-radius: 4px;
  font-size: 7px;

  color: white;
  padding: 1px 2px 1px 2px;
}
.stepbutton.active .bottom-text {
  border: 1px solid #c05a56;

  color: #c05a56;
}

.number img {
  width: 20px;
  height: 20px;
  margin: auto;
}
</style>

