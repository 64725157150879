import { createRouter, createWebHistory } from "vue-router";
import StartPage from "../components/StartPage.vue";
import PlayPage from "../components/PlayPage.vue";
import LeaderboardPage from "../components/LeaderboardPage.vue";
import HelpPage from "../components/HelpPage.vue";

const routes = [
  {
    path: "/",
    name: "Start",
    component: StartPage,
  },
  {
    path: "/play",
    name: "Play",
    component: PlayPage,
  },
  {
    path: "/leaderboard",
    name: "Leaderboard",
    component: LeaderboardPage,
  },
  {
    path: "/help",
    name: "Help",
    component: HelpPage,
  },
];

const router = createRouter({
  history: createWebHistory(""),
  routes,
});

export default router;
